import { useState, useEffect } from "react";
import CloseIcon from '../assets/close-icon.png';
import FullScreenIcon from '../assets/fullscreen-icon.png';
import ShareIcon from '../assets/share-icon.png';
import FBIcon from '../assets/fb.png';
import TWIcon from '../assets/tw.png';
// import {ReactComponent as FacebookIcon} from '../assets/facebook.svg';
// import {ReactComponent as TwitterIcon} from '../assets/twitter.svg';

import { Tween } from "react-gsap";
// import { useNavigate } from "react-router-dom";
import { openFullscreen } from './Model';

export default function Nav() {

    // const btn = document.querySelector('#share');
    // const [setActive] = useState(false);
    const [ showDefaultShare, setShowDefaultShare ] = useState(false);
    const [ seen, setIsSeen ] = useState(0);
    const [ showing, setShowing ] = useState(true);
    // const [ collectiveCreditsActive, setCollectiveCreditsActive ] = useState(false);
    // const navigate = useNavigate();

    let tweenprops;
    if (showDefaultShare===false) tweenprops = {opacity:1};

    let iclass = 'shareIcon';
    let sclass = 'default-share';
    if (showDefaultShare===true) iclass += ' up';
    if (showDefaultShare===true) sclass += ' active';
    
    function handleShareClick(event) {
        console.log('share click');
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
            (/Intel Mac/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2)){
            // const response = await fetch(event.target.getAttribute('data-share-image'));
            // const blob = await response.blob();
        
            // const image = new File([blob], 'image.jpg', { type: "image/jpeg" });
            
            function getCurrentURL() {
            return window.location.href;
            }
            const shareLink = getCurrentURL();

            const shareData = {
                    text: "Fant▲isie",
                    url: shareLink,
            }
            navigator.share(shareData);
            console.log('share enabled');

        }
        else{
                console.log('share not enabled');
                setShowDefaultShare( !showDefaultShare );
        } 
        setIsSeen(1);
    }
    function handleBackClick(){
        window.location.href = 'https://playreplay.ca';
    }

    useEffect(() => {
        // todo. play sound.
        let timeout = setTimeout(hideNav, 5000);
        function showNav() {
            clearTimeout(timeout);
            setShowing(true);
            timeout = setTimeout(hideNav, 5000);
        }
        function hideNav() {
            setShowing(false);
        }
        document.addEventListener('mousemove', (event) => {
          // only show nav if moving around top of screen.
          if (event.clientY<=100) showNav();
        });
  
      },[setShowing]);

    return (
            <nav className={`flex w-full p-2 pb-4 md:p-6 justify-between align-baseline absolute top-0 left-0 z-[101] ${!showing&&'hidden'}`}>
                <button id="collective" onClick={handleBackClick}>
                    <img src={CloseIcon} className="close-icon" alt="back to PLAYrePLAY" />
                </button>
                <button onClick={handleShareClick} id="share" className="share" data-share-title="fantaisie" data-share-url="https://fantaisie.playreplay.ca">
                <Tween to={tweenprops}>
                    <img src={ShareIcon} className="share-icon" alt="Share" />
                </Tween>
                </button>
                <button id="fullscreen-toggle" onClick={openFullscreen}>
                    <img src={FullScreenIcon} className="fullscreen-icon" alt="Fullscreen" />
                </button>
                <div className={sclass}>
                    <div className="share-wrap">
                        <a id="fb" rel="noopener noreferrer" target="_blank" className="social fb"
                        href="https://www.facebook.com/sharer/sharer.php?u=https://fantaisie.playreplay.ca"
                        data-size="large" data-share-title="fantaisie" data-share-url="https://fantaisie.playreplay.ca">
                            <Tween
                                from={showDefaultShare?{opacity:0, duration: 0.5, x:15}:{opacity:seen, duration: 0.5, x:0}}
                                to={showDefaultShare?{opacity:1, duration: 0.5, x:0}:{opacity:0, duration: 0.5, x:15}}
                            >
                                <img src={FBIcon} className="fb-icon" alt="Share to Facebook" />
                            </Tween>                              
                        </a>
                        <a id="tw" rel="noopener noreferrer" target="_blank" className="social tw"
                        href="https://twitter.com/intent/tweet?text=https://fantaisie.playreplay.ca"
                        data-size="large" data-share-title="fantaisie" data-share-url="https://fantaisie.playreplay.ca">
                             <Tween
                                from={showDefaultShare?{opacity:0, duration: 0.5, x:-15}:{opacity:seen, duration: 0.5, x:0}}
                                to={showDefaultShare?{opacity:1, duration: 0.5, x:0}:{opacity:0, duration: 0.5, x:-15}}
                            >
                                <img src={TWIcon} className="tw-icon" alt="Share to Twitter" />
                            </Tween> 
                        </a>
                    </div>
                </div>
            </nav>
        
    );
}
