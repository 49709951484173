import { useState } from "react";
import ModeIcon from "./ModeIcon";

const takeover_countdown = 10;
const takeover_countdown_steps = 5;

const loading_countdown_steps = 12;
const takeover_solo_time = 10; // seconds.



function Selector({ list, time, onSelect, current, clicked, toggled, engaged, takeoverEngaged, isMobile, onEffect }) {

    const takeover_start = list.find(e=>e.type==='takeover').start;
    // const [ catShown, setCatShown ] = useState(false);

    function getClass(e){
        let c = e.id.toLowerCase() + ' ' + e.type; 
        


        
        if (e.type==='takeover'){
            if (Number(e.start) <= Number(time)) { // already passed start time.
                if (takeoverEngaged>0) {
                    c += ' active';
                    // setTakeoverActivated(true);
                // } else if (takeoverActivated) {
                //     c += ' active';
                    if (e.num===clicked) c += ' clicked current';
                } else { // if not clicked, then disable it.
                    c += ' disabled';
                }
            } else if ( (Number(e.start)-takeover_countdown) <= Number(time) ){
                if (takeoverEngaged<0) {
                    // if not clicked, start countdown.
                    c += ' active';
                    const diff = Number(time) - (Number(e.start)-takeover_countdown);
                    const diff_percent = diff / takeover_countdown;
                    const cd_step = Math.floor( diff_percent * takeover_countdown_steps );
                    c += ' count-' + cd_step;
                
                // if clicked, show loading.
                } else {
                    c += ' loading';
                    const diff = Number(time) - Number(takeoverEngaged);
                    const diff_percent = diff / (Number(e.start)-Number(takeoverEngaged));
                    const l_step = Math.floor( diff_percent * loading_countdown_steps );
                    c += ' load-' + l_step;
                }
            }
        } else {
            if (Number(e.start) + Number(e.length) <= Number(time)) {
                c += ' disabled';
            } else if (Number(e.start) <= Number(time)) { 
                if (takeoverEngaged<0) {
                    c += ' active';
                    if (e.id==='Cat' && Number(e.start)+5 >= Number(time)){
                        //  setCatShown(true);
                         c += ' hilite';
                    }
                } else { // takeover engaged
                    // if (Number(time) < takeover_start) { // if takeover hasn't started yet, enable
                    //     c += ' active';
                    // } else 
                    if (Number(time) > takeover_start + takeover_solo_time) { // if enough time has elapsed, re-enable the buttons.
                        c += ' active';
                    } else {
                        c += ' disabled';
                    }
                }
            }

            if (e.type==='toggle' && toggled===e.num){
                // c += ' t-'+toggled;
                c += ' current';
            }

            if (takeoverEngaged>0 && Number(time)<takeover_start) return c;
            
            if (e.num===clicked) c += ' clicked';
            if (e.num===current) c += ' current';
        }
        
        return c;
    }

    return (<>
        <div className={`main videonav d-${engaged} xl:px-6 m-${isMobile}`}>
            {list.filter(e=>e.type!=='toggle').map(e=>
                <ModeIcon classString={getClass(e)} onSelect={onSelect} onEffect={onEffect} e={e} key={`li-${e.id}`} />
            )}
            {!isMobile && <>
                <div className="divider"></div>
                {list.filter(e=>e.type==='toggle').map(e=>
                <ModeIcon classString={getClass(e)} onSelect={onSelect} onEffect={onEffect} e={e} key={`li-${e.id}`} />
                )}
            </>}
        </div>
    </>);
}

export default Selector;