import React, { useEffect, useRef, useState, forwardRef } from "react";

import { Tween } from "react-gsap";

import glitchEffect from '../assets/Glitch Sound.mp3';

const VideoPlayer = forwardRef(( props, ref ) => {
    
    const videoRef = useRef(null);
    const canvRef = useRef(null);
    // const audioRef = useRef(null);

    const [isPlaying, setIsPlaying] = useState(!props.isFirst);

    // const [isBuffering, setIsBuffering] = useState(false);
    const [isGlitch, setIsGlitch ] = useState(props.isGlitch);
    const [lastNum, setLastNum] = useState(-1);
    const [lastTime, setLastTime] = useState(0);
    const [currentNum, setCurrentNum] = useState(0);

    const [currentTimeSec, setCurrentTimeSec] = useState(-1); //current time of the video in seconds
    const [durationSec, setDurationSec] = useState(-1); // // current duration of the video in seconds

    const [offsetDelta,setOffsetDelta] = useState(0);

    const getSrc = () => {
      let src = props.src;
      if (props.offset>0){
        src += '#t='+props.offset;
      }
      return src;
    }

    const handlePlayClick = () => {
        // on video 1, rupture symbol showing, play the rupture
        // if (props.num===1 && props.ruptureShowing && !props.rupturePlaying){
        //   props.onRuptureClick(true);
        //   return;
        // }
        // // on video 1, rupture already playing. kill the rupture
        // if (props.num===1 && props.ruptureShowing && props.rupturePlaying){
        //   props.onRuptureClick(false);
        //   return;
        // }

        // if (isPlaying) {
        //   videoRef.current.pause();
        //   setIsPlaying(false);
        // } else {
          videoRef.current.play();
          setIsPlaying(true);
        // }
    }
    
    const handleCanPlay = () => {
      // setIsBuffering(false);
      // if (props.num>1){
        // tell parent that we can play now.
        // console.log(props.num,'can play!');
        // console.log(firstTime,videoRef.current.duration);
        
        // if (props.isFirst && props.num===0 && !props.isGlitch) {
          if (props.setDuration) props.setDuration(videoRef.current.duration);
          props.onStateChange(props.num,'buffered');
        // }
        setDurationSec(videoRef.current.duration);
      // }
    }
    const handleIsPlaying = () => {
      // console.log(props.num,'is playing!');
      if (!props.isFirst && !props.isGlitch) props.onStateChange(props.num,'buffered');

      if (offsetDelta!==0){
        var n = new Date();
        console.log('played in',n.getTime()-offsetDelta,'ms');
      }
    }

    useEffect( ()=>{
      // if (props.isGlitch) return;
      if (currentTimeSec>-1) 
        props.setTime( currentTimeSec );
    }, [currentTimeSec]);

    const handleTimeUpdate = (e)=>{
      // console.log('update',e);
      setCurrentTimeSec(videoRef.current.currentTime);
    }

    const handleEnded = ()=>{
      props.onStateChange(props.id,'complete',currentTimeSec);
      setIsPlaying(false);
    }

    // HANDLE change to glitch state.
    useEffect( ()=>{
      
      setIsGlitch(props.isGlitch);
      if (props.isGlitch) {
        console.log('handle glitch',props.isGlitch);
        // videoRef.current.pause();
        // capture video and insert into background.
        const ctx = canvRef.current.getContext('2d');
        ctx.drawImage(videoRef.current,0,0,640,360);
        props.onGlitch(true);

        /*
        // start glitch
        const interval = setInterval(()=>{
          console.log('glitch',videoRef.current.currentTime);
          const cT = videoRef.current.currentTime;
          videoRef.current.currentTime = cT-.5;
        },500);
        return () => clearInterval(interval); */
      }
    },[props.isGlitch]);

    // HANDLE PRELOAD COMPLETE FROM GLITCH STATE.
    useEffect( ()=>{
      if (props.noffset && !props.isFirst){
        var n = new Date();
        setOffsetDelta(n.getTime());
        console.log('new offset calculated',props.noffset);
        videoRef.current.currentTime = props.noffset;
        videoRef.current.play();
        props.onGlitch(false);
      }
    },[props.noffset]);

    // HANDLE USER'S FIRST ENGAGEMENT (click on icon in nav bar)
    useEffect( ()=>{
      if (props.isEngaged){
        setIsPlaying(true);
        videoRef.current.play();
      }
    },[props.isEngaged])

    let playerClass = 'player-wrap';
      playerClass += ' debug';
      playerClass += ' f-'+props.isFirst;
      playerClass += ' g-'+isGlitch;

    if (window.location.search)
      playerClass += ' p-'+window.location.search.substring(1);

    return (
      <section id={`video-${props.index}`} className={playerClass}>

        <video 
          className='video-player' 
          src={getSrc()}
          ref={videoRef}
          autoPlay={false} // !props.isFirst}
          preload="auto"
          playsInline={true}
          // onClick={handlePlayClick}
          onCanPlay={props.isGlitch?null:handleCanPlay}
          onPlaying={props.isGlitch?null:handleIsPlaying}
          onTimeUpdate={handleTimeUpdate}
          onEnded={props.isGlitch?null:handleEnded}
          disableRemotePlayback={true}
        ></video>
        {/* <audio src={glitchEffect} ref={audioRef} loop={true} autoPlay={false} /> */}

        {/* show scrub bar if not glitch */}
        {!isGlitch && <div className="video-controls">
              {durationSec>0 && <input
              type="range"
              min="0"
              max={props.vstart+durationSec}
              className="timeline"
              // defaultValue={props.vstart+currentTimeSec}
              value={props.vstart+currentTimeSec}
              // onChange={()=>{}}
              // onChange={(e) => {
              //     setCurrentTimeSec(e.target.value);
              //     videoRef.current.currentTime = e.target.value;
              // }}
              />}
        </div>}

        <canvas ref={canvRef} width="640" height="360" />
        <div className="effect" />

        {/* </Tween> */}

      </section>
    )
});

export default VideoPlayer;