
import React, {useState, useEffect, useRef} from "react";
import { Reveal, Tween } from "react-gsap";
import logosrc from '../assets/fantaisie-logo.png';

function EndFrame({settings,nav}) {

    const [ fScroll, setFScroll ] = useState(0);
    const frame = useRef(null);

    useEffect(()=>{
        const onScroll = () => setFScroll(frame.current.scrollTop);
        // clean up code
        frame.current.removeEventListener('scroll', onScroll);
        frame.current.addEventListener('scroll', onScroll, { passive: true });
        return () => frame.current.removeEventListener('scroll', onScroll);
    },[frame]);

    return (
        <>
            {/* <Tween 
                from={{ y:300,stagger: 0.4, delay:1.5 }}
                to={{ y:0,stagger:0.4,delay:1.5}}
                duration={1.5}
            > */}
                <div className="fullscreen end-frame" ref={frame}>
                    <div className="end-frame-inner w-full text-center">
                        <Tween
                            from={{ opacity:0, stagger: 0.4 }}
                            to={{ opacity:1,stagger:0.4 }}
                            duration={1}
                        >
                            <h1 className={fScroll>0?'py-16 scrolled':'py-16'} id="h1"><img src={logosrc} alt="Autolysis" /></h1>
                        </Tween>
                        <Tween
                            from={{ opacity:0, stagger: 0.4 }}
                            to={{ opacity:1,stagger:0.4,delay:1 }}
                            duration={1}
                        >
                            <div>
                                <div className='w-full mx-auto max-w-screen-2xl flex flex-col justify-center lg:grid lg:grid-cols-3 gap-8 lg:gap-16 px-12'>
                                    {settings.credits.map((credit,index) => {
                                        return (
                                            <div className='artist' key={index} data-index={index}>
                                                 <img src={credit.imgURL} alt={credit.title} />
                                                <div>
                                                <h2 className="mb-2 text-lg leading-6">{credit.title}<br/>{credit.role}</h2>
                                                <p className="leading-5" dangerouslySetInnerHTML={{__html:credit.excerpt}}></p>  
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </Tween>
                        
                    </div>
                </div>
          {/* </Tween> */}
        </>
        
    );
}

export default EndFrame;