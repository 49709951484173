import React from 'react';
import { useEffect, useState } from 'react';
import Nav from './components/Nav';
import MainProject from './components/MainProject';

import './App.scss';

function App() {

  const [ showFlipMsg, setShowFlipMsg ] = useState(false);

  // This will run one time after the component mounts
  useEffect(() => {

    const onResize = (e)=>{
      // check mobile
      const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      // check is portrait.
      const isPortrait = window.innerHeight > window.innerWidth;
      const isTablet = Math.max( window.innerWidth, window.innerHeight ) >= 1024;

      console.log(isMobile,isPortrait,isTablet);
      setShowFlipMsg(isMobile && isPortrait && !isTablet);
    }
    onResize();
    
    window.addEventListener('resize', onResize);
      // Remove the event listener when component unmounts
    return () => window.removeEventListener('resize', onResize);
  }, [setShowFlipMsg]);

  return (
    <div className="App">
      <Nav />
      <MainProject /> 
      {showFlipMsg && <div className="flip-msg"><span>Please flip your phone! <em>Participate in the full Fant▲isie experience by joining from your Mac or PC.</em></span></div>}
    </div>
     
  );
}

export default App;
