import React, { useEffect, useRef, useState } from "react";


export default function InsetVideo ({num,src,offset,noffset,id,onStateChange}) {

    const videoRef = useRef(null);

    const getSrc = () => {
        let osrc = src;
        if (offset>0){
          osrc += '#t='+offset;
        }
        return osrc;
    }

    const handleCanPlay = () => {

        // console.log(num,'can play!');
        // console.log(firstTime,videoRef.current.duration);
        
        // // if (props.isFirst && props.num===0 && !props.isGlitch) {
        // if (props.setDuration) props.setDuration(videoRef.current.duration);
        onStateChange(num,'buffered');
        // // }
        // setDurationSec(videoRef.current.duration);

    }
    const handleIsPlaying = () => {
        // console.log(num,'is playing!');
        // if (!props.isFirst && !props.isGlitch) props.onStateChange(props.num,'buffered');

        // if (offsetDelta!==0){
        //     var n = new Date();
        //     console.log('played in',n.getTime()-offsetDelta,'ms');
        // }
    }
    const handleEnded = ()=>{
        onStateChange(num,'complete');
        // setIsPlaying(false);
    }

    // HANDLE PRELOAD COMPLETE FROM GLITCH STATE.
    useEffect( ()=>{
        if (noffset){
          console.log('new offset calculated',noffset);
          videoRef.current.currentTime = noffset;
          videoRef.current.play();
        }
      },[noffset]);

    return (
        <div className='inset-video-player'>
            <video
            src={getSrc()}
            ref={videoRef}
            autoPlay={false} // !props.isFirst}
            preload="auto"
            playsInline={true}
            onCanPlay={handleCanPlay}
            onPlaying={handleIsPlaying}
            onEnded={handleEnded}
            disableRemotePlayback={true}
            ></video>
        </div>
    )
}