import React, { useEffect, useRef, useState } from "react";
// import { useNavigate } from "react-router-dom";
import VideoPlayer from "./VideoPlayer";
import InsetVideo from "./InsetVideo";
import EndFrame from "./EndFrame";
import Selector from "./Selector";
// import { Tween } from "react-gsap";
import settings from '../../src/config.json';

import toggleEffect from '../assets/Icon Appears - Disappears.mp3';
import clickedEffect from '../assets/Icon Selected.mp3';
import glitchEffect from '../assets/Glitch Sound.mp3';

// todo.
// - handle toggle videos
// - handle cake video - loading state, countdown state

const scenesrc = [
  {num:0, 'id':'Concert', 'src':'https://playreplay-fantaisie-store.s3.amazonaws.com/ConcertMode_H264_HD.lbr.mp4', 'msrc':'https://playreplay-fantaisie-store.s3.amazonaws.com/ConcertMode_H264_HD.480p.mp4', 'type': 'main', 'start':0.0, 'length':424.0 },
  {num:1, 'id':'Cat', 'src':'https://playreplay-fantaisie-store.s3.amazonaws.com/CatMode_H264_HD.lbr.mp4', 'msrc':'https://playreplay-fantaisie-store.s3.amazonaws.com/CatMode_H264_HD.480p.mp4', 'type': 'main', 'start':65.29, 'length':371.0 }, // 1:05:07
  {num:2, 'id':'Singer', 'src':'https://playreplay-fantaisie-store.s3.amazonaws.com/Singer_H264_HD.lbr.mp4', 'msrc':'https://playreplay-fantaisie-store.s3.amazonaws.com/Singer_H264_HD.480p.mp4', 'type': 'toggle', 'start': 108.17, 'length':302.0 }, // 1:48:04
  {num:3, 'id':'Rave', 'src':'https://playreplay-fantaisie-store.s3.amazonaws.com/RaveMode_H264_HD.lbr.mp4', 'msrc':'https://playreplay-fantaisie-store.s3.amazonaws.com/RaveMode_H264_HD.480p.mp4', 'type': 'main', 'start':109.88, 'length':311.0 }, // 1:49:21
  {num:4, 'id':'Tapper', 'src':'https://playreplay-fantaisie-store.s3.amazonaws.com/Tapper_H264_HD.lbr2.mp4', 'msrc':'https://playreplay-fantaisie-store.s3.amazonaws.com/Tapper_H264_HD.480p2.mp4', 'type': 'toggle', 'start': 109.88, 'length':313.0 }, // 1:49:21
  {num:5, 'id':'POV', 'src':'https://playreplay-fantaisie-store.s3.amazonaws.com/POVMode_H264_HD.lbr.mp4', 'msrc':'https://playreplay-fantaisie-store.s3.amazonaws.com/POVMode_H264_HD.480p.mp4', 'type': 'main', 'start': 130.63, 'length':298.0 }, // 2:10:15
  {num:6, 'id':'Cake', 'src':'https://playreplay-fantaisie-store.s3.amazonaws.com/CakeMode_H264_HD.lbr.mp4', 'msrc':'https://playreplay-fantaisie-store.s3.amazonaws.com/CakeMode_H264_HD.480p.mp4', 'type': 'takeover', 'start': 232.08, 'length':228.0 }, // 3:52:02
  {num:7, 'id':'Bat', 'src':'https://playreplay-fantaisie-store.s3.amazonaws.com/BatMode_H264_HD.lbr.mp4', 'msrc':'https://playreplay-fantaisie-store.s3.amazonaws.com/BatMode_H264_HD.480p.mp4', 'type': 'main', 'start': 283.67, 'length':146.0 } // 4:43:16
];

export default function MainProject() {

    const [ counter, setCounter ] = useState(0); // use to track how many videos added to queue

    const [ masterTime, setMasterTime ] = useState(0.0); // use to track current playhead
    const [ masterDuration, setMasterDuration ] = useState(0.0); // will come from clip 0
    const [ engaged, setEngaged ] = useState(false); // use to track first interaction with video
    const [ takeoverEngaged, setTakeoverEngaged ] = useState(-1); // use to track interaction with takeover clip.

    const [ endFrameActive, setEndFrameActive ] = useState(false); // use to display endframe on completion

    const [ isDebug, setIsDebug ] = useState(window.location.search==='?debug'); // used?

    const [ currentScene, setCurrentScene ] = useState(0); // use to track current video
    const [ vidlist, setVidList ] = useState([]); // use to track current video queue

    const [ toggleSrc, setToggleSrc ] = useState({}); // use to track current toggled video

    const [ takeoverTimer, setTakeoverTimer ] = useState(null);

    // audio handling
    const glitchRef = useRef(null);
    const clickRef = useRef(null);
    const toggleRef = useRef(null);
    
    const onMainChangeState = (id,val)=>{
      // console.log('received state change',id,currentScene,val);
      if (val==='buffered'){
        if (id===currentScene) return; // skip this if we're already on the same video
        setCurrentScene(id);


        // on buffered, check elapsed time between click and buffer
        // seek current to new time
        // play from new time
        const offsetDec = masterTime - scenesrc[id].start;
        console.log('play',offsetDec,'into clip',id);
        vidlist[ vidlist.length-1 ].noffset = offsetDec;

        // calculate new offset for toggle if it's currently on.
        if (toggleSrc.vnum) {
          const tOffset = masterTime - scenesrc[toggleSrc.vnum].start;
          setToggleSrc( { ...toggleSrc, noffset:tOffset });
        }

        // clear out all glitching clips
        //setTimeout( ()=>{
          const newlist = vidlist.filter((v)=>!v.glitch);
          setVidList( newlist );
        //},100); // on a delay to prevent black screen between glitch clearing out and main clip playing. */
      }
      if (val==='complete'){
        // setRuptureShowing(false);
        if (endFrameActive === false) {
          // console.log('endframe');
          setEndFrameActive(true);
          setTimeout( ()=> {
            // console.log('stop glitch');
            onGlitch(false);
          }, 1000); // kill glitch noise after 1 second.
        } else {
          setEndFrameActive(false);
        }
      }
    };
    const onSubChangeState = (id,val)=>{
      console.log('received sub state change',id,val);
      if (val==='buffered') {
        const offsetDec = masterTime - scenesrc[id].start;
        console.log('play',offsetDec,'into clip',id);
        setToggleSrc( { ...toggleSrc, noffset:offsetDec });
        // vidlist[ vidlist.length-1 ].noffset = offsetDec;
      } else if (val==='complete') {
        setToggleSrc({}); // hide it.
      }
    }

    const isMobile = ()=>{
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
        (/Intel Mac/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2);
    }

    const onClickClip = (e)=>{
      if (!engaged){
        // trigger clip to play.
        setEngaged(true); // takes nav out of intro mode.
        return;
      }
      
      console.log(e.num,e.id);

      const offsetDec = masterTime - scenesrc[e.num].start;
      console.log('buffer',offsetDec,'into clip',e.num);

      const src = isMobile()
        ? scenesrc[e.num].msrc
        : scenesrc[e.num].src;

      // HANDLE TOGGLE TYPE VIDEOS (tapper and singer)
      if (e.type==='toggle'){
        if (toggleSrc.vnum && toggleSrc.vnum===e.num) {
          setToggleSrc( {} );
        } else {
          setToggleSrc( { vnum:e.num, offset:offsetDec, src:src, id:scenesrc[e.num].id } );
        }
        return;
      }

      // HANDLE TAKEOVER VIDEO
      if (e.type==='takeover'){
        if (offsetDec<0) { // takeover has not started yet. set up countdown/etc.
          setTakeoverEngaged( masterTime );
          const takeover_timer = setTimeout( ()=>{
            console.log('start playing takeover');

            const newlist = vidlist;

            // set current clip to glitch
            newlist[ newlist.length-1 ].glitch = true;
            newlist[ newlist.length-1 ].main = false;

            // push new clip
            newlist.push( { index:counter, vnum:e.num, offset:0, main:true, first:false, src:src, id:scenesrc[e.num].id } );
            setCounter(counter+1);

            setVidList( newlist );
            setToggleSrc( {} );

          }, -offsetDec*1000);
          setTakeoverTimer(takeover_timer);
          return;
        }
      }
      console.log('to?',takeoverEngaged,takeoverTimer);
      if (takeoverTimer) {
        clearInterval(takeoverTimer);
        setTakeoverTimer(null);
      }
      // setTakeoverEngaged(-1); // otherwise, not engaged.
      if (currentScene===e.num) return; // skip if we're already playing the clip. this will happen if we click cake, then click back

      const newlist = vidlist;

      // set current clip to glitch
      newlist[ newlist.length-1 ].glitch = true;
      newlist[ newlist.length-1 ].main = false;

      // push new clip
      newlist.push( { index:counter, vnum:e.num, offset:offsetDec, main:true, first:false, src:src, id:scenesrc[e.num].id, start:scenesrc[e.num].start, length:scenesrc[e.num].length } );
      setCounter(counter+1);

      setVidList( newlist );

      
    }

    const setRelativeTime = (rt)=>{
      const st = scenesrc[currentScene].start;
      setMasterTime(st+rt);
    }

    const onGlitch = (isOn) => {
      // console.log('onGlitch',isOn);
      if (isOn) {
        glitchRef.current.play();
      } else {
        glitchRef.current.pause();
      }
    }
    const onEffect = (which) => {
      if (which==='click') {
        clickRef.current.currentTime = 0;
        clickRef.current.play();
      } else {
        toggleRef.current.currentTime = 0;
        toggleRef.current.play();
      }
    }
    
    useEffect(() => {

      // set up first clip.
      const src = isMobile()
        ? scenesrc[0].msrc
        : scenesrc[0].src;

      setVidList( [ { index:counter, vnum:0, offset:0, main:true, first:true, src:src, id:scenesrc[0].id, start:scenesrc[0].start, length:scenesrc[0].length } ] );
      setCounter(counter+1);

    },[]);

    return (
        
          // <Tween 
          //   from={{ opacity:0, stagger: 0.4, delay:.4 }}
          //   to={{ opacity:1,stagger:0.4,delay:.4 }}
          // >
            <>
            <div className="video-wrap" data-time={masterTime} data-duration={masterDuration}>

              {vidlist.toReversed().map( v =>
                <VideoPlayer key={`v-${v.index}`} index={v.index} num={v.vnum} id="main" src={v.src} 
                  offset={v.offset} noffset={v.noffset} onStateChange={onMainChangeState} onGlitch={onGlitch}
                  isDebug={isDebug} isGlitch={v.glitch || endFrameActive} isFirst={v.first} isEngaged={engaged} 
                  setTime={setRelativeTime} setDuration={v.first?setMasterDuration:null}
                  vstart={v.start} vlength={v.length} />
              )}
              

            </div>

            {toggleSrc.vnum && 
              <InsetVideo num={toggleSrc.vnum} src={toggleSrc.src} offset={toggleSrc.offset} noffset={toggleSrc.noffset} id={toggleSrc.id} onStateChange={onSubChangeState} />
            }

            <Selector list={scenesrc} time={masterTime} onSelect={onClickClip} current={currentScene} clicked={vidlist.length>0 ? vidlist[vidlist.length-1].vnum : 0} toggled={toggleSrc.vnum || -1} engaged={engaged} takeoverEngaged={takeoverEngaged} isMobile={isMobile()} onEffect={onEffect} />

            {endFrameActive && <EndFrame settings={settings.endframe} /> }

            <audio src={glitchEffect} ref={glitchRef} loop={true} autoPlay={false} />
            <audio src={toggleEffect} ref={toggleRef} loop={false} autoPlay={false} />
            <audio src={clickedEffect} ref={clickRef} loop={false} autoPlay={false} />
            </>
          // </Tween>
    );
}
